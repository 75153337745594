@import 'variables';
body{
  width: 100%;
  height: 100vh;
  min-height: 500px;
  padding: 0;
  margin: 0;
  overflow: scroll;
  font: {
    family: $fontRoboto;
  }
  @media #{$media} and ($max: $min-tablet-width) {
    width: 100%;
    min-height: 100vh;
  }
}
.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  justify-items: center;
  width: 100%;
  height: 100vh;
  min-height: 500px;
  @media #{$media} and ($max: $min-tablet-width) {
    width: 96%;
    min-height: 100vh;
    margin: 0 auto;
  }
}
.logo {
  width: 450px;
  @media #{$media} and ($max: $min-tablet-width) {
    width: 100%;
  }
}
h1 {
  padding: 0;
  margin: 0;
  color: $colorRed;
  text-align: center;
  font: {
    size: 46px;
    style: italic;
    weight: 700;
  }
  @media #{$media} and ($max: $min-tablet-width) {
    width: 100%;
    font: {
      size: 26px;
    }
  }
}
h2 {
  color: $colorBlack;
  text-align: center;
  padding: 0;
  margin: 20px 0 0;
  line-height: 44px;
  text-transform: uppercase;
  font: {
    size: 19px;
    style: italic;
    weight: 700;
  }
  @media #{$media} and ($max: $min-tablet-width) {
    width: 100%;
    font: {
      size: 16px;
    }
  }
}
