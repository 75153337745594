@import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&display=swap&subset=cyrillic,cyrillic-ext,latin-ext');
// TYPOGRAPHY ....................... Font-family, font-size
// SIZES ............................ Sizes
// BORDERS .......................... Borders
// COLORS ........................... Colors
// TRANSITIONS ...................... Transitions time
// ANIMATIONS ....................... Animations time
// EASING ........................... Easing type
// DEVICES .......................... Devices size
// -------------------------------------------------------------------------------------------------

// -------------------------------------------------------------------------------------------------
// TYPOGRAPHY
// -------------------------------------------------------------------------------------------------

$fontRoboto: 'Roboto', sans-serif;

$fontSize: 10px;

// -------------------------------------------------------------------------------------------------
// SIZES
// -------------------------------------------------------------------------------------------------

// $button: 10rem;

// -------------------------------------------------------------------------------------------------
// BORDERS
// -------------------------------------------------------------------------------------------------

// $border-radius: ;

// -------------------------------------------------------------------------------------------------
// COLORS
// -------------------------------------------------------------------------------------------------

$colorWhite: rgba(255, 255, 255, 1);
$colorBlack: rgba(0, 0, 0, 1);

$colorDarkMain: rgba(78, 88, 120, 1);
$colorDarkSecondary: rgb(134, 151, 203);
$colorBlue: rgb(65, 139, 249);
$colorRed: rgb(255, 0, 0);
$colorHeaderGreen: #273135;

$colorHeaders: rgba(78, 88, 120, 1);
$colorDark: rgba(78, 88, 120, 1);
$colorGray: rgb(51, 51, 51);

$colorGrayDark: rgba(78, 88, 120, 1);
$colorFilter: rgba(164, 176, 220, 0.14);
$colorLight: rgba(219, 223, 241, 1);
$gray: #ccc;
$colorGreen: rgba(48, 129, 105, 1);
$colorGreenLight: rgb(44, 163, 14);
$colorGreenUltraLight: rgba(44, 163, 14, 0.6);
$green: rgba(48, 129, 105, 1);
$colorRed: rgba(210, 6, 6, 1);
$colorRedLight: rgba(210, 6, 6, 0.2);
$colorIndigo: rgb(56, 40, 115);
$colorPurple: rgba(158, 107, 218, 1);

$min-mobile-width: 320px;
$max-mobile-width: 480px;
$min-tablet-width: 768px;
$max-tablet-width: 1024px;
$max-pc-width: 1920px;

// $mobile: "(min-width: #{$min-mobile-width})";
$mobileLandscape: "(min-width: #{$max-mobile-width})";
$tablet: "(min-width: #{$min-tablet-width})";
$tabletLandscape: "(min-width: #{$max-tablet-width})";

%content {
  width: 100%;
  min-width: $min-mobile-width;
  max-width: $max-pc-width;
  margin: 0 auto;
}

$media: screen;
$min: min-width;
$max: max-width;

$small: 320px;
$middle: 468px;
$normal: 768px;
$big: 1024px;
$large: 1280px;
$hd: 1980px;